jQuery(document).ready(function($) {
  function get_suggestions(request) {
    $.ajax({
      url: "/suggestions/" + request.trim(),
      type: "GET",
      dataType: "script",
      success: function () {
        console.log("success")
      },
      error: function() {
        console.log("error")
      }
    });
  }

  $('#request').keyup(function() {
    let value = $(this).val();
    if (value.length > 1) {
      get_suggestions(value);
    } else {
      $('#suggestions').html("");
      $('#sub_categories_suggestions').html("");
      $('#autocomplete').css({'visibility': 'hidden', 'opacity': 0});
      focus_element = false;
    }
  });

  let focus_element = false;

  $(document).keydown(function(e){
    if (e.which === 38 && $('#autocomplete').css('visibility') !== 'hidden') { // flèche du haut
      if (focus_element.length) {
        if (focus_element.prev().length > 0 && focus_element.prev().hasClass('suggestion') ) { // une suggestion précède la suggestion actuellement en focus dans la même ul
          focus_element = focus_element.prev();
        } else if (focus_element.parent().siblings().first().children().length > 0) { // une suggestion précède la suggestion courante dans une autre ul
          focus_element = focus_element.parent().siblings().first().find('li').last();
        } else { // la suggestion actuelle est la première suggestion de l'autocomplete, il faut mettre le focus sur la dernière
          focus_element = $('#autocomplete').find('li').last();
        }
      } else { // Aucun élément de l'autocomplete n'a le focus
        if ($('#sub_categories_suggestions').children().length > 0) {
          focus_element = $('#sub_categories_suggestions li:last');
        } else {
          focus_element = $('#suggestions li:last');
        }
      }

      focus_element.children().focus();
      e.preventDefault(); // permet d'annuler l'action par défaut de l'événement (ici scroll quand on clique sur les flèches haut et bas)
    }

    if (e.which === 40 && $('#autocomplete').css('visibility') !== 'hidden') { // flèche du bas
      if (focus_element.length) {
        if (focus_element.next().length > 0) { // une suggestion succède directement la suggestion actuellement en focus
          focus_element = focus_element.next();
        } else {
          let parent = focus_element.parent();

          if (parent.next().children().length > 0) { // il existe une ul non vide qui succède l'ul où la suggestion actuelle est en focus
            focus_element =  parent.next().find('li').first()
          } else { // la suggestion actuelle est la dernière suggestion de l'autocomplete, il faut mettre le focus sur la première
            focus_element = $('#autocomplete').find('li').first();
          }
        }
      } else { // Aucun élément de l'autocomplete n'a le focus
        if ($('#suggestions').children().length > 0) {
          focus_element = $('#suggestions li:first');
        } else {
          focus_element = $('#sub_categories_suggestions li:first');
        }
      }

      focus_element.children().focus();

      e.preventDefault();
    }
  });

});